
import Accounting from 'images/careers/accounting.svg'
import Legal from 'images/careers/legal.svg'
import Marketing from 'images/careers/marketing.svg'
import Sales from 'images/careers/sales.svg'
import Tech from 'images/careers/tech.svg'
import Admin from 'images/careers/Admin_Icon.svg'

const careersList = [
  {
    image:Marketing,
    alt:'marketing-icon',
    header:'Marketing',
    location:'Location: Shaw Blvd.',
    links:[
      {
        to:'/modal/junior-graphic-designer',
        label:'Junior Graphic Designer'
      },
    ]
  },
  {
    image:Accounting,
    alt:'finance-icon',
    header:'Finance',
    location:'Location: Shaw Blvd.',
    links:[
      {
        to:'/modal/financial-analyst',
        label:'Financial Analyst'
      },
    ]
  },
  {
    image:Sales,
    alt:'sales-icon',
    header:'Sales',
    location:'Location: Shaw Blvd.',
    links:[
      {
        to:'/modal/business-development-manager',
        label:'Business Development Manager'
      },
    ]
  },
  {
    image:Tech,
    alt:'tech-icon',
    header:'Tech Team',
    location:'Location: Shaw Blvd.',
    links:[
      {
        to:'/modal/android-mobile-developer',
        label:'Android Mobile Developer'
      },
      {
        to:'/modal/senior-software-developer',
        label:'Senior Software Developer'
      },
      {
        to:'/modal/software-developer',
        label:'Software Developer'
      },
    ]
  },
  {
    image:Legal,
    alt:'legal-icon',
    header:'Legal',
    location:'Location: Shaw Blvd.',
    links:[
      {
        to:'/modal/legal-and-compliance-associate',
        label:'Legal and Compliance Associate'
      },
      {
        to:'/modal/in-house-counsel',
        label:'In-house Counsel'
      }
    ]
  },
  {
    image:Admin,
    alt:'admin-icon',
    header:'Admin',
    location:'Location: Shaw Blvd.',
    links:[
      {
        to:'/modal/admin-staff',
        label:'Admin Staff'
      },
      {
        to:'/modal/company-driver',
        label:'Company Driver'
      }
    ]
  },
]

export default careersList;