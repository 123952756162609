import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../components/css/careers.css'
import Career from 'images/careers/header_careers.jpg'
import Accounting from 'images/careers/accounting.svg'
import Admin from 'images/careers/admin.svg'
import Marketing from 'images/careers/marketing.svg'
import Operations from 'images/careers/operations.svg'
import Sales from 'images/careers/sales.svg'
import Tech from 'images/careers/tech.svg'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll'
import Seo from '../components/Seo'
import careersList from '../data/static/CareersListData'
const Careers = () => {
  return (
    <div>
      <Seo
        title="Digipay PH | Careers"
        description="Digipay is a digital payments and financial services platform with
  a mobile wallet, an industry leading biller ecosystem,
  and an interoperable backend."
        keywords="digipay.ph/careers/, Work, Careers, Digipay Careers, Work at Digipay, careers@digipay.ph, Admin, Tech, Work with us"
      />
      <Header />

      <img className="career-banner" src={Career} alt="career-image" />
      <div className="career-section">
        {careersList.map((career,index)=>{
          return (
            <div key={index}>
              <Row>

                <Col className="d-none d-md-block">
                  <img className="career-img" src={career.image} alt={career.alt} />
                </Col>

                <Col className="accounting">
                  <h2 className="career-header">{career.header}</h2>
                  <h5>{career.location}</h5>
                  <ul>
                    {
                      career.links.map((link,index) => {
                        return (
                        <li key={index}>
                          <Link to={link.to} state={{ modal: true }}>
                            {link.label}
                          </Link>
                        </li>
                        )
                      })
                    }
                  </ul>
                </Col>
                
              </Row>
              <hr />
            </div>
          )
        })}
  
        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
          <h5 id="resume">
            Send your resume at{' '}
            <span>
              <a href="mailto: careers@digipay.ph" id="resume-email" onClick={()=> window.open('mailto: careers@digipay.ph')}>
                careers@digipay.ph
              </a>
            </span>
          </h5>
        </ScrollAnimation>
      </div>
  
      <Footer />
    </div>
  )
}

export default Careers
